$(document).ready(function() {

    $(function() {
      
            //  BASE URL
            var l = window.location;
            var baseurl = l.protocol + "//" + l.host + "/" + l.pathname.split('/')[1]+ "/";
            if (l.host=="localhost") {
                var baseurl = "http://kraak.test/";
             } else {
                var baseurl = "http://kraak.net/"
            }
            // ===============================================
            // MASONRY ====================================
            // ===============================================
            var $grid = $('.masonry').imagesLoaded( function() {
                // init Masonry after all images have loaded
                $grid.masonry({
                    // set itemSelector so .grid-sizer is not used in layout
                    itemSelector: '.masonry-item ',
                    // use element for option
                    columnWidth: '.masonry-sizer',
                    percentPosition: true
                });
              });

            var $gridFourColcol = $('.masonry-four-col').imagesLoaded( function() {
                // init Masonry after all images have loaded
                $gridFourColcol.masonry({
                    // set itemSelector so .grid-sizer is not used in layout
                    itemSelector: '.masonry-item ',
                    // use element for option
                    columnWidth: '.masonry-sizer',
                    percentPosition: true
                });
              });


            // ===============================================
            // NAVIGATION ====================================
            // ===============================================
            // NAV bar
            // https://www.taniarascia.com/responsive-dropdown-navigation-bar/
            $('nav ul li > a:not(:only-child)').click(function(e) {
                $(this).siblings('.nav-dropdown').toggle();
                $('.nav-dropdown').not($(this).siblings()).hide();
                e.stopPropagation();
            });
            $('body').click(function() {
                $('.nav-dropdown').hide();
            });
            document.querySelector('#nav-toggle').addEventListener('click', function() {
                this.classList.toggle('active');
            });
            $('#nav-toggle').click(function() {
                $('nav ul').toggle();
            });
            
            // content padding top
            var heightHeader = $(".navigation").height();
            if ($(window).width() > 1023) {
                $('.content').css('padding-top', heightHeader+40);
                $( window ).resize(function() {
                    var heightHeader = $(".navigation").height();
                    $('.content').css('padding-top', heightHeader+40);
                });
            }
            
           
            // ===============================================
            // AVANT GAURDIAN ====================================
            // ===============================================

            var relatedContentOpen = 0;
            $('.js-open-content').click(function(e) {
                e.preventDefault()
           
                if (relatedContentOpen == 0) {
                    $(".related-content").css('max-height', 'none');
                    $(".related-content").removeClass('closed');
                    $(".related-content-show-hand").hide();

                    scrollTopVal = $(window).scrollTop() + 200
                    $("html, body").animate({ scrollTop: scrollTopVal+"px" });
                    relatedContentOpen = 1;
                } else {
                    $(".related-content").css('max-height', '250px');
                    $(".related-content").addClass('closed');
                    $(".related-content-show-hand").show();

                    scrollTopVal = $(window).scrollTop() - 200
                    $("html, body").animate({ scrollTop: scrollTopVal+"px" });
                    relatedContentOpen = 0;
                }
            });

     
            // ===============================================
            // RECORDS ====================================
            // ===============================================
           
            var hover = 0;            
            $('.js-record-cover-spotify').mouseenter(function() {
                if (hover == 0) {
                    setSpotifyPlayerSizes();
                }
                $('.js-record-cover-bandcamp').addClass('playing');                            
                $('.spotify-player').show();
             });
             $('.js-record-cover-spotify').mouseleave(function() {
                $('.spotify-player').hide();
                $('.js-record-cover-bandcamp').removeClass('playing');                            
             }); 

             $('.js-record-cover-bandcamp').mouseenter(function() {
                console.log('bandcamp!');
                if (hover == 0) {
                    setBandCampPlayerSizes()
                }
                $('.bandcamp-player').show();
                $('.js-record-cover-bandcamp').addClass('playing');                            
             });
              $('.js-record-cover-bandcamp').mouseleave(function() {
                 $('.bandcamp-player').hide();
                 $('.js-record-cover-bandcamp').removeClass('playing');                            
                 
             }); 
             function setSpotifyPlayerSizes() {
                heightToSet = $('.js-record-cover img').height();
                widthToSet = $('.js-record-cover img').width();
                $('.js-spotify-player').height(heightToSet);
                $('.js-spotify-player').width(widthToSet);
                hover = 1;    
             }

            function setBandCampPlayerSizes() {
                heightToSet = $('.js-record-cover img').height();
                widthToSet = $('.js-record-cover img').width();
                $('.js-bandcamp-player').height(heightToSet);
                $('.js-bandcamp-player').width(120);
               // $('.js-record-cover img').css('opacity', '0');
                hover = 1;  
             }
             $( window ).resize(function() {
                if ($(".js-record-cover-spotify")[0]) {
                    setSpotifyPlayerSizes();
                }
                if ($(".js-record-cover-bandcamp")[0]) {
                    setBandCampPlayerSizes();
                }
                if ($(window).width() > 860) {
                    $('.nav-list').hide();
                    console.log('hide');
                }
            });
              
           
            
            
            // ===============================================
            // OVERLAY ====================================
            // ===============================================
            /* Open */
            $('.js-open-overlay').click(function(e) {
                e.preventDefault()
                $("body").addClass("overlay-active");
                
                $(".js-close-overlay").show().css('opacity','1').css('z-index','999');
                $(".js-open-overlay").hide();
                document.getElementById("overlay").style.height = "100%";
            });

            
            $('.js-close-overlay').click(function(e) {
                e.preventDefault()                
                $("body").removeClass("overlay-active");   
                $(".js-open-overlay").show().css('opacity','1').css('z-index','999');
                $(".js-close-overlay").hide(); 
                document.getElementById("overlay").style.height = "0%";
            });

          

            


       
    
    });



});
